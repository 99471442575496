<template>
  <v-container>
    <v-row class="text-center">

      <v-col class="my-4 text-left" cols="12">
        <v-alert outlined border="left" color="primary" icon="mdi-vector-difference">
          <div class="text-h6">
            Sabuuun
          </div>
          <div>２つのファイル(PDFもしくはワード)のサブンを見つけることができます。</div>
        </v-alert>
      </v-col>

      <v-col class="mb-5" cols="6">
        <v-card flat color="background">
          <v-card-title class=" headline font-weight-bold mb-3">
            <v-chip class="file1" small text-color="file1Dark">ファイル１</v-chip>
          </v-card-title>

          <v-card-text class="my-5">
            <v-file-input placeholder="Select Word file" accept=".docx, .pdf" color="black"
              prepend-icon="mdi-text-box-outline" outlined :show-size="1000" v-model="files1">
            </v-file-input>
          </v-card-text>

        </v-card>
      </v-col>

      <v-col class="mb-5" cols="6">
        <v-card flat class="background">
          <v-card-title class=" headline font-weight-bold mb-3">
            <v-chip class="file2" small text-color="file2Dark">ファイル２</v-chip>
          </v-card-title>

          <v-card-text class="my-5">
            <v-file-input placeholder="Select Word file" accept=".docx, .pdf" color="black"
              prepend-icon="mdi-text-box-outline" outlined :show-size="1000" v-model="files2">
            </v-file-input>
          </v-card-text>

        </v-card>
      </v-col>

      <v-col class="mt-5" cols="12">
        <v-btn rounded depressed @click="onClick" img="mdi-vector-difference" large color="primary" min-width="300">
          <v-icon class="mr-5">mdi-vector-difference</v-icon>
          チェック
        </v-btn>
      </v-col>

      <v-col v-if="res !== null && res.pages" class="text-left mt-10 mb-5" cols="12">
        <v-divider></v-divider>
        <h1 class="display-1 font-weight-bold text-center my-2">
          <v-chip class="background px-10" large label block>サブン</v-chip>
        </h1>
        <v-divider></v-divider>

        <template v-for="(page, index) in res.pages ">

          <v-chip :key="index + '_title'" class="background mt-5" small> {{ index + 1 }} ページ</v-chip>
          <v-card-text :key="index + '_img'" class="mt-3 ">
            <v-row>
              <v-col class="mb-2 background" cols="4">
                <v-chip class="file1 mb-3" x-small text-color="file1Dark">ファイル１</v-chip>
                <v-hover v-slot="{ hover }" class="word-card">
                  <v-card :elevation="hover ? 5 : 1" :class="{ 'on-hover': hover }">
                    <v-img :src="page.file1" @click="() => { selectImage(page, 0) }" />
                  </v-card>
                </v-hover>
              </v-col>
              <v-col class="mb-2 background" cols="4">
                <v-chip class="file2 mb-3" x-small text-color="file2Dark">ファイル２</v-chip>

                <v-hover v-slot="{ hover }" class="word-card">
                  <v-card :elevation="hover ? 5 : 1" :class="{ 'on-hover': hover }">
                    <v-img :src="page.file2" @click="() => { selectImage(page, 1) }" />
                  </v-card>
                </v-hover>

              </v-col>
              <v-col class="mb-2 background" cols="4">
                <v-chip class="primary mb-3" x-small text-color="background">サブン</v-chip>

                <v-hover v-slot="{ hover }" class="word-card">
                  <v-card :elevation="hover ? 5 : 1" :class="{ 'on-hover': hover }">
                    <v-img :src="page.diff_img" @click="() => { selectImage(page, 2) }" />
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
        </template>

      </v-col>

    </v-row>

    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="background" indeterminate />
    </v-overlay>

    <v-dialog v-model="isShowDialog" width="1000" scrollable>
      <v-card flat v-if="selectedPage !== null" min-height="90vh" class="white">
        <v-card-title>
          <v-tabs v-model="menuIndex">
            <v-tab small class="mr-2">
              ファイル１
            </v-tab>
            <v-tab small class="mr-2">
              ファイル２
            </v-tab>
            <v-tab small class="mr-2">
              サブン（画像）
            </v-tab>
            <v-tab small class="mr-2">
              サブン（文字列）
            </v-tab>
          </v-tabs>
        </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text class="background pt-5">
          <v-tabs-items v-model="menuIndex" min-height="500px">
            <v-tab-item>
              <v-card outlined :elevation="12">
                <v-img :src="selectedPage.file1" />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card outlined :elevation="12">
                <v-img :src="selectedPage.file2" />
              </v-card>
            </v-tab-item>
            <v-tab-item>

              <v-card outlined :elevation="12">
                <v-img :src="selectedPage.diff_img" />
              </v-card>

            </v-tab-item>
            <v-tab-item>
              <div v-html="selectedPage.diff_html" class="overflow-x-auto"></div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Main',

  data() {
    return {
      files1: null,
      files2: null,
      res: null,
      overlay: false,
      selectedDiffHtml: '',
      menuIndex: 0,
      selectedPage: null,
      isShowDialog: false,
      isShowDiffImage: true,
      menus: ["file1", "file2", "diff_img", "diff_html"]
    }
  },

  methods: {
    onClick() {

      this.post()
    },

    selectImage(page, index) {
      this.menuIndex = index
      this.selectedPage = page
      this.isShowDialog = true
      this.selectedDiffHtml = page.diff_html
    },
    async post() {
      this.overlay = true
      this.res = null
      const url = "https://pzezyxsdv42fyyz3ysxfvajmqe0pyqwm.lambda-url.ap-northeast-1.on.aws/"
      const data = new FormData();

      data.append("file1", this.files1);
      data.append("file2", this.files2);
      const headers = { "Content-Type": "multipart/form-data" };
      try {
        const res = await axios.post(url, data, { headers });
        if (res?.data) {
          this.res = res.data
        }
      } catch (e) {
        console.log(e)
      }

      this.overlay = false
    },

  }
}
</script>

<style>
.word-card {
  cursor: pointer;
}

table.diff {
  border: solid !important;
}

table.diff td {
  padding: 5px !important;
}

table.diff[summary="Legends"] {
  display: none;
}

table.diff {
  width: 100%;
  border-collapse: collapse;
  max-width: 1000px;
}

td.diff_next {
  width: 0px !important;
  display: none;
}

td.diff_header {
  width: 30px !important;
  background-color: #eceff1;
}

.diff_add {
  background-color: #aaffaa
}

.diff_chg {
  background-color: #ffff77
}

.diff_sub {
  background-color: #ffaaaa
}
</style>