<template>
  <v-app>
    <v-app-bar app color="white" flat dark>
      <div class="d-flex align-center ">
        <v-chip color="primary" dark label>
          <v-icon class="mr-5" color="">mdi-vector-difference</v-icon>

          <span class="">Sabuuun</span>
        </v-chip>
      </div>

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <Main />
    </v-main>
  </v-app>
</template>

<script>
import Main from './components/Main';

export default {
  name: 'App',

  components: {
    Main,
  },

  data: () => ({
    //
  }),
};
</script>
