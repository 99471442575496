import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo.darken4,
        secondary: colors.cyan.darken1,
        accent: colors.cyan.black,
        error: colors.red.accent3,
        background: "#f7f7f8",
        file1: "#C7F5EB",
        file2: '#FFE5F4',
        file1Dark: "#0E8161",
        file2Dark: '#E50087',
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
})
